<template>
  <div>
      <card-component :title="'IP Banla'" icon="domain">
        <form @submit.prevent="save">
          <b-field horizontal label="IP">
            <b-input
                size="is-small"
                v-model="form.ip"
                icon="account"
                placeholder="IP"
                name="ip"
                required/>
          </b-field>
          <b-field horizontal label="Saat">
            <b-input
                size="is-small"
                v-model="form.ttl"
                icon="account"
                placeholder="SAAT"
                name="ttl"
                required/>
          </b-field>
          <hr/>
          <div class="is-flex is-justify-content-end is-flex-grow-1">
            <div class="control mr-2">
              <b-button
                  native-type="submit"
                  type="is-primary"
                  size="is-small">
                {{ $route.params.id ? "Güncelle" : "Ekle" }}
              </b-button>
            </div>
          </div>
        </form>
      </card-component>
  </div>
</template>

<script>
import mapValues from "lodash/mapValues"
import CardComponent from "@/components/CardComponent"
import {ProfileService} from "@/services"

export default {
  name: "UserCreateOrUpdate",
  components: {CardComponent},
  props:['bannedList'],
  data() {
    return {
      isLoading: false,
      form: {
        ip: null,
        ttl: 1
      },
    }
  },
  methods: {
    save() {
      ProfileService.addBan({...this.form, ttl: this.form.ttl * 3600})
          .then(() => {
            this.bannedList()
            this.$buefy.toast.open({
              message: "Ban eklendi",
              position: "is-top-right",
              type: "is-success",
            })
            this.reset()
          })
          .catch((err) =>
              this.$buefy.toast.open({
                message: err.message,
                position: "is-top-right",
                type: "is-danger",
              })
          )
    },
    reset() {
      this.form = mapValues(this.form, (item) => {
        if (item && typeof item === "object") {
          return []
        }
        return null
      })
    },
  },
}
</script>
